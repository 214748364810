<template>
  <div>
    <v-card-text class="pb-1" style="padding: 0;">
      <v-form ref="guardianInfoForm" lazy-validation>
        <v-timeline class="user_add_form" align-top dense>
          <v-timeline-item color="red" small>
            <v-layout pt-3>
              <v-flex>
                <strong>Primary Guardian</strong>
                <div class="caption">
                  Person responsible for student's guardianship. Will have
                  permission to login to the system and view students
                  records(attendance,exam results, routine, bill payment etc).
                </div>
                <v-flex xs11>
                  <v-flex pt-3 xs12>
                    <v-container style="padding:0" grid-list-md>
                      <v-layout row wrap>
                        <v-flex xs5>
                          <!--{{student.guardian}}-->
                          <v-autocomplete
                            @change="selectBiologicalGuardian"
                            outlined
                            dense
                            autocomplete="off"
                            :items="
                              guardianItems.length
                                ? guardianItems
                                : student.guardian &&
                                  student.guardian.length &&
                                  student.guardian[0].id
                                ? student.guardian
                                : []
                            "
                            label="First Name*"
                            required
                            class="pa-0"
                            v-model="student.selectedGuardian.id"
                            name="name"
                            item-text="name"
                            item-value="id"
                            hide-no-data
                            :search-input.sync="searchGuardian"
                            data-vv-name="select"
                            :rules="guardianValidation.validation.guardianRule"
                          />
                          <small>
                            <v-icon small>phone</v-icon>
                            <strong>
                              {{ guardianSelected.primary_mobile }}
                            </strong>
                            &nbsp;&nbsp;&nbsp;
                            <v-icon small>email</v-icon>
                            <strong>
                              {{ guardianSelected.email || "N/A" }}
                            </strong>
                          </small>
                        </v-flex>
                        <v-flex sm4 xs12>
                          <v-autocomplete
                            @change="selectBiologicalGuardian"
                            outlined
                            dense
                            autocomplete="off"
                            :items="relations"
                            label="Relation"
                            required
                            class="pa-0"
                            v-model="student.selectedGuardian.relation"
                            name="name"
                            :rules="guardianValidation.validation.relationRule"
                          />
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-flex>
                </v-flex>
              </v-flex>
            </v-layout>
          </v-timeline-item>
          <v-timeline-item color="purple lighten-1" small>
            <v-layout pt-3>
              <v-flex>
                <strong>
                  <v-icon>supervised_user_circle</v-icon>
                  Parents
                </strong>
                <div class="caption">
                  On the basis of biological relationship(Father, Mother and
                  Grand Father)
                </div>
                <v-flex pt-3 xs11>
                  <v-container style="padding:0" grid-list-md>
                    <v-layout row wrap>
                      <v-flex xs12 sm4>
                        <v-text-field
                          outlined
                          dense
                          autocomplete="off"
                          label="Grand Father Full Name"
                          required
                          class="pa-0"
                          v-model="student.selectedGuardian.family.grand_father"
                          name="rank"
                        />
                      </v-flex>
                      <v-flex xs12 sm4>
                        <v-text-field
                          outlined
                          dense
                          autocomplete="off"
                          label="Father Full Name"
                          required
                          class="pa-0"
                          v-model="student.selectedGuardian.family.father"
                          name="rank"
                        />
                      </v-flex>
                      <v-flex xs12 sm4>
                        <v-text-field
                          outlined
                          dense
                          autocomplete="off"
                          label="Mother Full Name"
                          required
                          class="pa-0"
                          v-model="student.selectedGuardian.family.mother"
                          name="rank"
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-flex>
              </v-flex>
            </v-layout>
          </v-timeline-item>
        </v-timeline>
        <v-card-actions style="padding-left: 80px; padding-bottom: 80px;">
          <v-btn
            color="warning"
            outlined
            @click="SET_GUARDIAN_UPDATE(false), $emit('cancel')"
          >
            <v-icon dark left>arrow_back</v-icon>
            Cancel
          </v-btn>

          <v-btn color="success" outlined @click="updateGuardianInfo"
            >Update Guardian Info</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Form from "@/library/Form";

export default {
  props: {
    isProfilePage: {
      type: Boolean,
      default: false,
    },
    student: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchGuardian: "",
      guardianList: [],
      relations: [
        "Grandfather",
        "Grandmother",
        "Father",
        "Mother",
        "Brother",
        "Sister",
        "Uncle",
        "Aunt",
        "Other",
      ],
      guardianForm: new Form({
        guardian_id: "",
        relation: "",
        family: {
          father: "",
          mother: "",
          grand_father: "",
        },
      }),
      guardianValidation: {
        validation: {
          valid: false,
          guardianRule: [(v) => !!v || "Please select a guardian"],
          relationRule: [(v) => !!v || "Please specify a relation"],
        },
      },
    };
  },
  watch: {
    searchGuardian: function(val) {
      if (!val) return;
      if (this.isLoading) return;
      this.isLoading = true;
      this.$rest
        .get("api/user-search/slim?type=guardian&search=" + val)
        .then(({ data }) => {
          this.guardianList = data.data;
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    // ...mapState(['student']),

    guardianItems() {
      return this.guardianList.map((entry) => {
        return Object.assign({}, entry, { Name: entry.name });
      });
    },
    guardianSelected() {
      let guardian = {};
      if (this.guardianItems.length) {
        let $this = this;
        this.guardianItems.map(function(data) {
          if ($this.student.selectedGuardian.id === data.id) {
            guardian = data;
          }
        });
      }
      return guardian;
    },
  },
  methods: {
    ...mapActions(["getStudentInfo"]),
    ...mapMutations(["SET_GUARDIAN_UPDATE"]),

    updateGuardianInfo() {
      if (this.$refs.guardianInfoForm.validate()) {
        this.guardianForm.guardian_id = this.student.selectedGuardian.id;
        this.guardianForm.relation = this.student.selectedGuardian.relation;
        this.guardianForm.family = this.student.selectedGuardian.family;

        let studentId = this.student.id;
        if (!studentId) studentId = this.student.data.id;

        this.$rest
          .put(
            "/api/student/" + studentId + "/guardian",
            this.guardianForm.data()
          )
          .then((res) => {
            this.$events.fire("notification", { message: res.data.message });

            this.$emit("complete");

            if (this.isProfilePage) {
              this.SET_GUARDIAN_UPDATE(false);
              this.getStudentInfo(
                "/api/student/" + this.$route.params.id
              ).then((res) => {});
            }
          })
          .catch((err) => {
            this.$events.fire("notification", {
              message: err.response.data.message,
              status: "error",
            });
            this.guardianForm.failure(err.response.data);
          });
      }
    },
    selectBiologicalGuardian() {
      if (this.student.guardian && this.student.guardian.length) {
        let guardian = {
          ...this.student.guardian[0],
        };
        if (
          guardian.relation !== "Father" ||
          guardian.relation !== "GrandFather" ||
          guardian.relation !== "Mother"
        ) {
          guardian.family = {};
        }

        if (
          this.student.guardian[0].family.grand_father &&
          this.student.guardian[0].family.grand_father.length < 1
        ) {
          this.student.guardian[0].family.grand_father = "";
        }
        if (
          this.student.guardian[0].family.father &&
          !this.student.guardian[0].family.father.length
        ) {
          this.student.guardian[0].family.father = "";
        }
        if (
          this.student.guardian[0].family.mother &&
          !this.student.guardian[0].family.mother.length
        ) {
          this.student.guardian[0].family.mother = "";
        }

        if (Object.keys(this.guardianSelected).length) {
          if (guardian.relation === "Father") {
            this.student.guardian[0].family.father = this.guardianSelected.Name;
            if (guardian.family.mother === guardian.family.father) {
              guardian.family.mother = "";
            }
            if (guardian.family.grand_father === guardian.family.father) {
              guardian.family.grand_father = "";
            }
          }
          if (guardian.relation === "Mother") {
            this.student.guardian[0].family.mother = this.guardianSelected.Name;
            if (
              guardian.family.father === this.student.guardian[0].family.mother
            ) {
              guardian.family.father = "";
            }
            if (guardian.family.grand_father === guardian.family.mother) {
              guardian.family.grand_father = "";
            }
          }
          if (guardian.relation === "Grandfather") {
            this.student.guardian[0].family.grand_father = this.guardianSelected.Name;
            if (guardian.family.mother === guardian.family.grand_father) {
              guardian.family.mother = "";
            }
            if (guardian.family.father === guardian.family.grand_father) {
              guardian.family.father = "";
            }
          }
        } else {
          if (!guardian.family.father) {
            guardian.family.father = "";
          }
          if (!guardian.family.mother) {
            guardian.family.mother = "";
          }
          if (!guardian.family.grand_father) {
            guardian.family.grand_father = "";
          }

          if (
            guardian.relation === "Father" &&
            !guardian.family.father.length
          ) {
            this.student.guardian[0].family.father = guardian.name;
            if (guardian.family.mother === guardian.family.father) {
              guardian.family.mother = "";
            }
            if (guardian.family.grand_father === guardian.family.father) {
              guardian.family.grand_father = "";
            }
          }
          if (
            guardian.relation === "Mother" &&
            !guardian.family.mother.length
          ) {
            this.student.guardian[0].family.mother = guardian.name;
            if (
              guardian.family.father === this.student.guardian[0].family.mother
            ) {
              guardian.family.father = "";
            }
            if (guardian.family.grand_father === guardian.family.mother) {
              guardian.family.grand_father = "";
            }
          }
          if (guardian.relation === "Grandfather") {
            this.student.guardian[0].family.grand_father = guardian.name;
          }
        }
      }
    },
  },
  mounted() {
    this.selectBiologicalGuardian();
  },
};
</script>
